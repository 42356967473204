<template>
	<div v-loading="showLoading" class="user-list">
		<div class="opt">
			<el-button type="primary" @click="add">增加用户</el-button>
		</div>
		<el-table :data="data" stripe>
			<el-table-column
				v-for="item in columns"
				:key="item.prop"
				:prop="item.prop"
				:label="item.label"
			>
			</el-table-column>
			<el-table-column label="操作">
				<template slot-scope="scope">
					<el-button
						size="small"
						@click="edit(scope.row)"
					>修改信息</el-button>
					<el-button
						size="small"
						type="danger"
						@click="deleteUser(scope.row.id)"
					>删除用户</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-dialog
			v-loading="addDialogLoading"
			:visible.sync="showAddDialog"
			title="增加用户">
			<el-form
				ref="userForm"
				label-width="100px"
				:model="formData"
				:rules="rules"
			>
				<el-form-item label="名称" prop="name">
					<el-input v-model.trim="formData.name"></el-input>
				</el-form-item>
				<el-form-item label="手机号" prop="phone">
					<el-input v-model.trim="formData.phone" maxlength="11"></el-input>
				</el-form-item>
				<el-form-item label="密码" prop="pwd">
					<el-input v-model.trim="formData.pwd" show-password></el-input>
				</el-form-item>
				<el-form-item label="确认密码" prop="confirmPwd">
					<el-input v-model.trim="formData.confirmPwd" show-password></el-input>
				</el-form-item>
				<el-form-item label="所属公司" prop="company">
					<el-select
						v-model="formData.company"
						placeholder="请选择所属公司"
						@change="updateRoleList"
					>
						<el-option
							v-for="item in companyList"
							:key="item.id"
							:label="item.name"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="层级" prop="role">
					<el-select v-model="formData.role" placeholder="请选择层级">
						<el-option
							v-for="item in roleList"
							:key="item.value"
							:label="item.name"
							:value="item.value"
						></el-option>
					</el-select>
				</el-form-item>
                <el-form-item label="接收短信" prop="sms">
                    <el-radio-group v-model="formData.sms">
                        <el-radio :label="1">接收短信通知</el-radio>
                        <el-radio :label="0">不接收短信通知</el-radio>
                    </el-radio-group>
                </el-form-item>
			</el-form>
			<div slot="footer">
				<el-button @click="showAddDialog = false">取 消</el-button>
				<el-button type="primary" @click="confirm">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
    import md5 from 'js-md5';
    import {getCompanyList, getUserList, deleteUser, addUser, modifyUser} from '../service/main';
    import {ROLE_MAP} from '../utils/commonUtils';

    const SALT = 'shenshuishuiwu';

    export default {
        name: 'UserList',
        data() {
	        const checkPhone = (rule, value, callback) => {
	            if (!value) {
                    callback('请输入手机号');
	            } else if (!/^1[3|4|5|6|7|8|9]\d{9}$/.test(value)) {
	                callback('手机号格式错误');
	            } else {
	                callback();
	            }
	        };
	        const checkPwd = (rule, value, callback) => {
                if (!value) {
                    if (this.formData.pwd) {
	                    callback('确认密码不能为空');
                    } else {
                        callback('密码不能为空');
                    }
                } else if (this.formData.pwd !== value) {
                    callback('两次密码不一致');
                } else {
                    callback();
                }
	        };
            return {
                showLoading: false,
                columns: [
                    {
                        prop: 'username',
                        label: '姓名'
                    },
                    {
                        prop: 'phone',
                        label: '手机号'
                    },
                    {
                        prop: 'region',
                        label: '区域'
                    },
                    {
                        prop: 'companyName',
                        label: '所属公司'
                    },
                    {
                        prop: 'roleValue',
                        label: '层级'
                    },
                    {
                        prop: 'smsEnable',
                        label: '接收短信通知'
                    }
                ],
                data: [],
	            showAddDialog: false,
	            showModifyDialog: false,
	            formData: {
                    name: '',
		            phone: '',
		            pwd: '',
		            confirmPwd: '',
		            role: '',
                    sms: 1
	            },
	            rules: {
                    name: [
                        {required: true, message: '请输入用户名', trigger: 'blur'},
                    ],
		            phone: [
                        {required: true, validator: checkPhone, trigger: 'blur'},
		            ],
		            pwd: [
                        {required: true, message: '请输入密码', trigger: 'blur'},
                        {min: 6, max: 12, message: '长度在 6 到 12 个字符', trigger: 'blur'}
		            ],
                    confirmPwd: [
                        {required: true, validator: checkPwd, trigger: 'blur'},
                        {min: 6, max: 12, message: '长度在 6 到 12 个字符', trigger: 'blur'}
                    ],
		            role: [
                        {required: true, message: '请选择用户角色', trigger: 'change'}
		            ],
                    company: [
                        {required: true, message: '请选择所属公司', trigger: 'change'}
                    ],
                    sms: [
                        { required: true, message: '请选择是否接收短信通知', trigger: 'change' }
                    ]
	            },
                addDialogLoading: false,
                companyList: [],
	            operationType: 'add',
	            roleList: []
            }
        },
        mounted() {
            this.showLoading = true;
            this.initCompany().then(() => {
	            this.query();
            });
        },
        methods: {
            add() {
                this.operationType = 'add';
                this.formData = {};
                this.showAddDialog = true;
            },
            edit(row) {
                this.operationType = 'edit';
                this.updateRoleList(row.company);
                this.formData = Object.assign({}, row, {name: row.username});
                this.showAddDialog = true;
            },
            updateRoleList(companyId) {
                const companyInfo = this.companyList.find(item => item.id === companyId);
                this.roleList = companyInfo.role.split(',').map(item => {
                    return {
                        name: ROLE_MAP[+item],
	                    value: +item
                    }
                });
            },
            initCompany() {
                return getCompanyList({all: 1}).then(res => {
                    const {list = []} = res.data;
                    this.companyList = list;
                    const companyMap = {};
                    this.companyList.forEach(item => companyMap[+item.id] = item.name);
                    this.companyMap = companyMap;
                });
            },
            query() {
                this.showLoading = true;

                getUserList({}).then(res => {
                    this.data = res.data.list.map(item => {
                        const temp = {...item}
                        temp.roleValue = ROLE_MAP[+temp.role]
                        temp.companyName = this.companyMap[+temp.company]
                        temp.smsEnable = item.sms ? '是' : '否'
                        return temp
                    });
                    this.showLoading = false;
                }).catch(err => {
                    this.showLoading = false;
                    this.handleError(err)
                })
            },
            deleteUser(id) {
                this.$confirm('是否删除该用户?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.showLoading = true;
                    deleteUser(id).then(res => {
                        const {code, msg} = res;
                        if (+code === 0) {
                            this.$message.success('删除成功');
                            this.query();
                        } else {
                            this.$message.error(msg);
                        }
                    }).catch(err => {
                        this.showLoading = false;
                        this.handleError(err)
                    });
                }).catch(() => {
                    console.log('cancel delete user');
                });
            },
            confirm() {
                this.$refs.userForm.validate(valid => {
                    if (valid) {
                        this.addDialogLoading = true;
                        const isAddType = this.operationType === 'add';
                        const api = isAddType ? addUser : modifyUser;
                        const params = Object.assign({}, this.formData);
                        params.pwd = md5(params.pwd + SALT);
                        api(params).then(res => {
	                        this.addDialogLoading = false;
                            const {code, msg} = res;
                            if (+code === 0) {
                                this.$message.success(isAddType ? '添加成功' : '修改成功');
                                this.showAddDialog = false;
                                this.query();
                            } else {
                                this.$message.error(msg);
                            }
                        }).catch(err => {
                            this.addDialogLoading = false;
                            this.showLoading = false;
                            this.handleError(err)
                        });
                    }
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
	.user-list {
		.opt {
			margin-bottom: 16px;
			text-align: left;
		}

		/deep/ .el-form {
			.el-form-item {
				display: flex;
			}
			.el-form-item__content {
				flex: 1;
				margin-left: 20px !important;
			}
			.el-select {
				width: 100%;
			}
		}
	}
</style>
